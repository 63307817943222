/*!

=========================================================
* Now UI Dashboard Angular - v1.3.0
=========================================================

* Product Page: https://www.creative-tim.com/product/now-ui-dashboard-angular
* Copyright 2020 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/now-ui-dashboard-angular/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
@import "bootstrap/scss/bootstrap";
@import "now-ui-dashboard/variables";
@import "now-ui-dashboard/mixins";

// Plugins CSS
@import "now-ui-dashboard/plugins/plugin-animate-bootstrap-notify";
@import "now-ui-dashboard/plugins/plugin-perfect-scrollbar";

// Core CSS
@import "now-ui-dashboard/buttons";
@import "now-ui-dashboard/inputs";
@import "now-ui-dashboard/typography";
@import "now-ui-dashboard/misc";
@import "now-ui-dashboard/checkboxes-radio";

// components
@import "now-ui-dashboard/navbar";
@import "now-ui-dashboard/page-header";
@import "now-ui-dashboard/dropdown";
@import "now-ui-dashboard/alerts";
@import "now-ui-dashboard/images";
@import "now-ui-dashboard/nucleo-outline";
@import "now-ui-dashboard/tables";
@import "now-ui-dashboard/sidebar-and-main-panel";
@import "now-ui-dashboard/footers";
@import "now-ui-dashboard/fixed-plugin";

// cards
@import "now-ui-dashboard/cards";
@import "now-ui-dashboard/cards/card-plain";
@import "now-ui-dashboard/cards/card-chart";
@import "now-ui-dashboard/cards/card-user";
@import "now-ui-dashboard/cards/card-map";

@import "now-ui-dashboard/responsive";

// Toast over-ride css
.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 50px 15px 15px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 0 12px #999999;
  color: #ffffff;
}

.mark {
  padding: 0.2em;
  background-color: yellow !important;
}
